var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"mx-1"},[_c('div',{staticClass:"d-flex sticky-top"},[_c('el-input',{attrs:{"placeholder":"Cerca una domanda"},model:{value:(_vm.questionSearch),callback:function ($$v) {_vm.questionSearch=$$v},expression:"questionSearch"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-search"},slot:"append"})],1),_c('el-checkbox',{staticClass:"ml-3 bg-white",attrs:{"label":"Mostra soluzioni","border":""},model:{value:(_vm.showSolutions),callback:function ($$v) {_vm.showSolutions=$$v},expression:"showSolutions"}})],1),(_vm.filteredQuestions)?_c('el-tabs',{staticClass:"mt-2",attrs:{"type":"border-card"},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('el-tab-pane',{attrs:{"label":"Domande di teoria","name":"theoreticalQuestions"}},[(_vm.filteredQuestions.theoreticalQuestions.length > 0)?_c('ol',{staticClass:"gradient-list"},_vm._l((_vm.filteredQuestions.theoreticalQuestions),function(theoreticalQuestion){return _c('li',{key:("theo-" + (theoreticalQuestion.id)),attrs:{"seq":theoreticalQuestion.id - 1000}},[_c('div',{domProps:{"innerHTML":_vm._s(theoreticalQuestion.question)}}),_c('ol',{attrs:{"type":"A"}},[_c('li',{class:[
                _vm.showSolutions && theoreticalQuestion.solution === 'A'
                  ? 'active'
                  : '' ]},[_c('span',{domProps:{"innerHTML":_vm._s(theoreticalQuestion.A)}})]),_c('li',{class:[
                _vm.showSolutions && theoreticalQuestion.solution === 'B'
                  ? 'active'
                  : '' ]},[_c('span',{domProps:{"innerHTML":_vm._s(theoreticalQuestion.B)}})]),_c('li',{class:[
                _vm.showSolutions && theoreticalQuestion.solution === 'C'
                  ? 'active'
                  : '' ]},[_c('span',{domProps:{"innerHTML":_vm._s(theoreticalQuestion.C)}})]),_c('li',{class:[
                _vm.showSolutions && theoreticalQuestion.solution === 'D'
                  ? 'active'
                  : '' ]},[_c('span',{domProps:{"innerHTML":_vm._s(theoreticalQuestion.D)}})])])])}),0):_c('div',[_vm._v(" Nessuna domanda corrispondente alla ricerca trovata ")])]),_c('el-tab-pane',{attrs:{"label":"Esercizi semplici","name":"simpleExercises"}},[(_vm.filteredQuestions.simpleExercises.length > 0)?_c('ol',{staticClass:"gradient-list"},_vm._l((_vm.filteredQuestions.simpleExercises),function(simpleExercise){return _c('li',{key:("simpl-" + (simpleExercise.id)),attrs:{"seq":simpleExercise.id - 2000}},[_c('div',{domProps:{"innerHTML":_vm._s(simpleExercise.question)}}),(simpleExercise.imageLink)?_c('div',{staticClass:"d-flex justify-content-end"},[_c('el-link',{staticClass:"correct-test__link mt-2",attrs:{"type":"primary"},on:{"click":function($event){return _vm.showImage(simpleExercise.imageLink)}}},[_vm._v(" Vedi immagine ")])],1):_vm._e(),_c('ol',{attrs:{"type":"A"}},[_c('li',{class:[
                _vm.showSolutions && simpleExercise.solution === 'A'
                  ? 'active'
                  : '' ]},[_c('span',{domProps:{"innerHTML":_vm._s(simpleExercise.A)}})]),_c('li',{class:[
                _vm.showSolutions && simpleExercise.solution === 'B'
                  ? 'active'
                  : '' ]},[_c('span',{domProps:{"innerHTML":_vm._s(simpleExercise.B)}})]),_c('li',{class:[
                _vm.showSolutions && simpleExercise.solution === 'C'
                  ? 'active'
                  : '' ]},[_c('span',{domProps:{"innerHTML":_vm._s(simpleExercise.C)}})]),_c('li',{class:[
                _vm.showSolutions && simpleExercise.solution === 'D'
                  ? 'active'
                  : '' ]},[_c('span',{domProps:{"innerHTML":_vm._s(simpleExercise.D)}})])])])}),0):_c('div',[_vm._v("Nessuna domanda corrispondente alla ricerca trovata")])]),_c('el-tab-pane',{attrs:{"label":"Esercizi difficili","name":"difficultExercises"}},[(_vm.filteredQuestions.difficultExercises.length > 0)?_c('ol',{staticClass:"gradient-list"},_vm._l((_vm.filteredQuestions.difficultExercises),function(difficultExercise){return _c('li',{key:("diffEx-" + (difficultExercise.id)),attrs:{"seq":difficultExercise.id - 3000}},[_c('div',{domProps:{"innerHTML":_vm._s(difficultExercise.question)}}),(difficultExercise.imageLink)?_c('div',{staticClass:"d-flex justify-content-end"},[_c('el-link',{staticClass:"correct-test__link mt-2",attrs:{"type":"primary"},on:{"click":function($event){return _vm.showImage(difficultExercise.imageLink)}}},[_vm._v(" Vedi immagine ")])],1):_vm._e(),_c('ol',{attrs:{"type":"A"}},[_c('li',{class:[
                _vm.showSolutions && difficultExercise.solution === 'A'
                  ? 'active'
                  : '' ]},[_c('span',{domProps:{"innerHTML":_vm._s(difficultExercise.A)}})]),_c('li',{class:[
                _vm.showSolutions && difficultExercise.solution === 'B'
                  ? 'active'
                  : '' ]},[_c('span',{domProps:{"innerHTML":_vm._s(difficultExercise.B)}})]),_c('li',{class:[
                _vm.showSolutions && difficultExercise.solution === 'C'
                  ? 'active'
                  : '' ]},[_c('span',{domProps:{"innerHTML":_vm._s(difficultExercise.C)}})]),_c('li',{class:[
                _vm.showSolutions && difficultExercise.solution === 'D'
                  ? 'active'
                  : '' ]},[_c('span',{domProps:{"innerHTML":_vm._s(difficultExercise.D)}})])])])}),0):_c('div',[_vm._v(" Nessuna domanda corrispondente alla ricerca trovata ")])])],1):_vm._e(),_c('el-dialog',{attrs:{"title":"Immagine","custom-class":"my-modal","visible":_vm.imageDialogVisible},on:{"update:visible":function($event){_vm.imageDialogVisible=$event}}},[_c('div',{staticClass:"text-center"},[_c('el-image',{attrs:{"src":_vm.selectedImageLink}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"placeholder"},slot:"placeholder"},[_vm._v(" Loading"),_c('span',{staticClass:"dot"},[_vm._v("...")])])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }